<script>
export default {
  name: "TabContentPanel",
  props: {
    titles: {
      type: Array,
      default() {return []}
    },
    list: {
      type: Array,
      default() {return []}
    }
  },
  emits: ["onLoad"],
  watch: {
    titles() {
      this.titles.forEach( e=> {
        this.my_titles.push(e.title);
        this.my_attrs.push(e.key);
      })
    }
  },
  data() {
    return {
      my_titles: [],
      my_attrs: [],
    }
  },
  mounted() {
    if(this.$refs.list) {
      this.$refs.list.scrollTo({top: 0});
    }
  },
  methods: {

  }
}
</script>

<template>
  <div class="tab-content-panel h-full flex flex-col">
    <div class="card-container h-full">
      <div class="title p-3 border-b bg-white">
        <div class="flex justify-between font-bold">
          <div class="col flex-1 text-center" v-for="title in my_titles">{{ title }}</div>
        </div>
      </div>
      <div class="list flex-1 bg-gray-100 overflow-y-auto" ref="list" v-if="list && list.length > 0">
        <van-list>
          <div class="item flex justify-between p-3 items-center border-b" :class="{'bg-white': i % 2 === 0, 'bg-gray-100': i % 2 === 1}" v-for="(item, i) in list">
            <p v-for="i in my_attrs">{{ item[i] }}</p>
          </div>
        </van-list>
      </div>
      <div v-else class="flex-1">
        <van-empty description="暂无信息" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.card-container {
  @apply flex flex-col rounded-3xl overflow-hidden shadow-md;
}

.list {
  @apply bg-white;
  .item {
    ::v-deep p {
      @apply flex-1 text-center truncate;
    }
  }
  ::v-deep .van-list__finished-text,::v-deep .van-list__loading {
    @apply bg-white;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-600;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* 轨道颜色 */
  }
}

.van-empty {
  @apply bg-white h-full;
}
</style>