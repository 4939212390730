<template>
  <div class="home flex flex-col p-4">
    <div class="card-container mb-3">
      <UserInfo></UserInfo>
      <PriceCount></PriceCount>
      <van-tabs v-model="activeName" line-height="0" title-active-color="#F00">
        <van-tab title="我的推广" name="a"></van-tab>
        <van-tab title="余额明细" name="b"></van-tab>
        <van-tab title="分佣明细" name="c"></van-tab>
      </van-tabs>
    </div>
    <div class="tab-content overflow-hidden flex-1 mb-4">
      <Subordinates v-if="activeName === 'a'"></Subordinates>
      <BillingDetails v-if="activeName === 'b' "></BillingDetails>
      <BrokerageDetails v-if="activeName === 'c' "></BrokerageDetails>
    </div>
    <Contact></Contact>
  </div>
</template>

<script>
import {Lazyload} from 'vant';
import Vue from "vue";
import UserInfo from "@/components/UserInfo.vue";
import PriceCount from "@/components/PriceCount.vue";
import Subordinates from "@/components/tabContent/Subordinates.vue";
import BillingDetails from "@/components/tabContent/BillingDetails.vue";
import BrokerageDetails from "@/components/tabContent/BrokerageDetails.vue";
import Contact from "@/components/Contact.vue";

Vue.use(Lazyload);
export default {
  name: 'HomeView',
  components: {
    Contact,
    BrokerageDetails,
    BillingDetails,
    Subordinates,
    PriceCount,
    UserInfo
  },
  data() {
    return {
      activeName: "a"
    }
  },
  mounted() {
    this.checkWithDrawValue();
  },
  methods: {
    checkWithDrawValue() {
      let withdrawSuccessValue = localStorage.getItem("withdraw-success");
      if(withdrawSuccessValue === "1") {
        this.activeName = "b";
        localStorage.removeItem("withdraw-success");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: calc(100vh - 3.5rem);
  @apply pb-0 bg-gray-200;
}

.card-container {
  @apply rounded-3xl shadow-md overflow-hidden;
}
</style>
