<script>
import TabContentPanel from "@/components/TabContentPanel.vue";

export default {
  name: "BillingDetails",
  components: {TabContentPanel},
  data() {
    return {
      page: 1,
      list: []
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    onLoad() {

    },
    async getList() {
      const res = await this.$axios.get("/commission/getUserBalanceLogList", {params: {page: this.page, _showLoading: true}});
      this.list = res.data.data;
      let other = res.other_data;
      let type_array = other.type_array;
      if(other && type_array) {
        for (let item of this.list) {
          item.type = type_array[item.type];
        }
      }
    }
  }
}
</script>

<template>
  <div class="h-full">
    <TabContentPanel
        :titles="[{title: '类型', key: 'type'}, {title: '金额', key: 'change_amount'}, {title: '创建时间', key: 'created_at'}]"
        :list="list"
        @onLoad="onLoad"
        ref="tabContentPanel"
    ></TabContentPanel>
  </div>
</template>

<style lang="scss" scoped>

</style>